import "../styles/globals.css";
import "../styles/responsive.css";
import type { AppProps } from "next/app";
import * as axios from "axios";
import { EnvVars } from "../utils/envVars";
import { setCurrentToken } from "../interceptors";
import { ReactQueryProvider } from "../providers/ReactQueryProvider";
import React, { createContext, useState } from "react";
import type { NextPage } from "next";
import { ToastContainer, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { RecoilRoot } from "recoil";
import { ProfileData } from "../recoil-state/profile-info/profile-data-types";
import Head from "next/head";
import "../helpers/moment-format";
import ReCaptchaProvider from "../providers/ReCaptchaProvider";

axios.default.defaults.baseURL = EnvVars.API;
axios.default.defaults.headers["Accept"] = "application/json";
axios.default.defaults.headers["Content-Type"] = "application/json";
axios.default.interceptors.request.use(setCurrentToken);

export type NextPageWithLayout = NextPage & {
  getLayout?: (page: React.ReactElement) => React.ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

declare global {
  interface Window {
    dataLayer: any[];
    recaptchaOptions: { useRecaptchaNet: boolean };
    heap: {
      identify: any;
      addUserProperties: any;
      push: any;
      load: any;
    };
  }
}

export const profileContext = createContext<any>({});

export default function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  const [profileInfo, setProfileInfo] = useState<ProfileData>();
  const getLayout = Component.getLayout ?? ((page) => page);
  return (
    <ReCaptchaProvider>
      <RecoilRoot>
        <Head>
          {/* Google Tag Manager */}
          {process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER && (
            <script
              dangerouslySetInnerHTML={{
                __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER}')`,
              }}
            />
          )}
          {/* Heapanalytics */}
          {process.env.NEXT_PUBLIC_HEAPANALYTICS && (
            <script
              type="text/javascript"
              dangerouslySetInnerHTML={{
                __html: `
               window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a);for(var n=function(e){return function(){window.heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n(p[o])};
          heap.load("${process.env.NEXT_PUBLIC_HEAPANALYTICS}");
            `,
              }}
            />
          )}
        </Head>

        {process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER && (
          <noscript>
            <iframe
              src={`https://www.googletagmanager.com/ns.html?id=${process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER}`}
              height="0"
              width="0"
              style={{ display: "none", visibility: "hidden" }}
            ></iframe>
          </noscript>
        )}

        <profileContext.Provider value={{ profileInfo, setProfileInfo }}>
          <ReactQueryProvider>
            <ToastContainer
              position="top-center"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              enableMultiContainer
              draggable
              pauseOnHover
              theme="colored"
              transition={Slide}
            />
            <ToastContainer
              transition={Slide}
              className="Toast_share"
              position="top-center"
              closeButton={false}
              hideProgressBar={true}
              style={{ width: "600px" }}
              enableMultiContainer
              containerId={"share"}
            />
            {getLayout(<Component {...pageProps} />)}
          </ReactQueryProvider>
        </profileContext.Provider>
      </RecoilRoot>
    </ReCaptchaProvider>
  );
}
